import React from "react"

const ImageContentPage = (props) => {
      return (
        <React.Fragment>
          {props.scr.length!==0 &&
          <div className="content col-1-1">
            <div className="masthead">
                <div className="masthead col-1-1">
                  {props.scr[0].link? (<a href={props.scr[0].link}><img src={props.scr[0].url} alt={props.scr[0].title} /></a> ) : (<img src={props.scr[0].url} alt={props.scr[0].title} /> )}
                </div>
            </div>
          </div>}
        </React.Fragment>
      )    
  }

export default ImageContentPage
